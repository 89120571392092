import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/admin/TheLayout'));
const TheLayoutUser = React.lazy(() => import("./containers/user/TheLayoutUser"));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/admin/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/admin" name="Home" render={props => <TheLayout {...props}/>} />
              <Route path="/nguoi-giup-viec" name="Giúp việc" render={props => <TheLayoutUser {...props}/>} />
              <Route path="/dich-vu" name="Dịch vụ" render={props => <TheLayoutUser {...props}/>} />
              <Route path="/lien-he" name="Liên hệ" render={props => <TheLayoutUser {...props}/>} />
              <Route path="/tin-tuc" name="Tin tức" render={props => <TheLayoutUser {...props}/>} />
              <Route path="/" name="Trang chủ" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
